import $ from 'jquery';

/**
 * Convert: US Date -> UK Date
 * Convert: UK Date -> US Date
 */
export function dateConvert(date, separatorTo, separatorFrom) {



    if( separatorTo ) {
        date = date.replaceAll(separatorFrom, separatorTo);
    } else {
        date = date.replaceAll('/', '-').replaceAll('.', '-');
    }

    // clean date
    date = date.substr(0,10);

    if( !separatorTo ) {
        separatorTo = '-';
    }

    // check if - character exists
    if( date.indexOf(separatorTo) >= 0 ) {
        return date.split(separatorTo).reverse().join(separatorTo);
    }

    return false;
}

export function isIsoWeek(date) {
    const split = date.split('W');
    if(split.length > 2 || split.length < 2) {
        return false;
    }

    const year = split[0];
    let weekOfYear = split[1];
    if(isNaN(parseInt(year)) || isNaN(parseInt(weekOfYear))) {
        return false;
    }

    weekOfYear = parseInt(weekOfYear);
    if(weekOfYear < 1 || weekOfYear > 53) {
        return false;
    }

    return true;
}

/**
 * Get plural or non-plural version of text
 * @param count
 * @param text
 * @param returnNum
 */
export function plural(count, text, returnNum) {
    // force to array to number
    if( $.isArray(count) ) {
        count = count.length;
    }

    // return number with txt
    if( returnNum ) {
        text = count + ' ' + text;
    }

    if( $.isNumeric(count) === false || count > 1 || count === 0 ){
        return text + 's';
    }

    return text;
}