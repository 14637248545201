import {BaseTab} from "./tacho_shared";
import {DateTime} from "luxon";
import CalendarTab from "./_calendar_tab";
import tachoCalendarIcons from "./../shared/tacho_calendar_icons";
import {AjaxSync} from "../shared/ajax_utilities";

export default class DriverCalendarTab extends CalendarTab
{
    #driverId;

    constructor($tab) {
        super($tab);

        this.#driverId = $('input#driver_id').val();

        this._$hostingTab.find('input#enable_links').click(this.#toggleLinkedDays.bind(this));
        this._$hostingTab.find('input#enable_elsewhere').click(this.fetchData.bind(this));
        this.setupContextMenus($tab.find('#tacho_calendar'));
    }

    rebuild_calendar(baseDate) {
        const $table = this._$hostingTab.find('table#tacho_calendar');
        let sourceURL = $table.data('render-url') + '/' + this.combineParams({
            'date': baseDate,
            'driver_id': this.#driverId,
        });

        $table.addClass('loading');
        AjaxSync({ url: sourceURL, method: 'GET' }, {
            done: (content) => {
                this._$hostingTab.find('table#tacho_calendar').html(content).addClass('loading');
                this._$hostingTab.find('#tacho_calendar.loading .tacho_calendar_controls').append(this.spinner);
                this.connectHandlers();
                this.fetchData();
                this.#toggleLinkedDays();
            }
        });
    }

    fetchData()
    {
        const $table = this._$hostingTab.find('table#tacho_calendar');
        if ($table.length === 0) {
            return;
        }
        let sourceURL = $table.data('source-url');
        if (sourceURL === null) {
            return;
        }
        let baseDate = DateTime.fromISO(this._$hostingTab.find('input#base_date').val());
        let showExternal = false
        if (this._$hostingTab.find('input#enable_elsewhere')[0].checked) {
            showExternal = true;
        }
        const parameters = this.combineParams({
            'month': baseDate.toFormat('yyyy-MM'),
            'driver_id': this.#driverId,
            'show_external': showExternal,
        });

        $table.addClass('loading');
        AjaxSync({
            url: sourceURL + '/' + parameters,
            method: 'GET',
            dataType: 'json'
        }, {
            done: (content) => {
                this.renderCalendar($table, content);
                this.calenderRendered($table);
            }
        });

        this._addonElements($table, parameters);
    }

    renderCalendar($table, content) {
        $table.find('li.Driving, li.Working, li.Available, li.Rest, li.OvernightRest, span.Infringement, span.SeriousInfringement, span.Letter').hide();

        const dataTypes = ['Driving','Working','Available','Rest','OvernightRest','Infringement','SeriousInfringement','Letter','DailyCheck','Ferry'];

        for(const f in content.data) {
            const a = content.data[f];
            const $cell = $table.find(`td#${f}`);

            for(const t of dataTypes) {

                let $item = $cell.find(`li.${t}, span.icon.${t}`);
                const tag = ( $item.length ) ? $item.prop("tagName").toLowerCase() : '';

                if ( a[t] ) {

                    if( t === 'Infringement' || t === 'SeriousInfringement' ) {
                        $cell.addClass('has-infringement');
                    }

                    if( t === 'Letter' || t === 'DailyCheck' || t === 'Ferry' ) {
                        $item = this.renderCalendarIcons($item, a[t], t);
                    } else if( tag === 'li' ) {
                        $item.html(`<span>${a[t]}</span>`);
                    } else if( tag === 'span' ) {
                        const textIcon = ( tachoCalendarIcons[t]?.name ) ? tachoCalendarIcons[t].name : t;

                        $item.attr('title', textIcon).html(tachoCalendarIcons[t]);
                    }

                    $item.show();
                } else {
                    $item.hide();
                }
            }

            if($cell.hasClass('week_summary')) {
                $cell.find('ul, li').css('cursor', 'pointer');
                $cell.css('cursor', 'pointer').off('click').on('click', this.displayDriverActivityTab.bind(this));
            }

            $cell.find('span.Infringement,span.SeriousInfringement').off('click').on('click', this.activateTab('infringements'));
            $cell.addClass(`has-data`);
        }

        const emptyValue = '0h 0m';

        // adding class for <td> .has-tacho-data .has-no-tacho-data
        $table.find('td.date').each(function(){
            const $li = $(this).find('ul li:visible');

            if( $li.length ) {
                let hasData = false;

                $li.each(function(k,v) {
                   if( $(v).text().trim().toLowerCase() !== emptyValue ) {
                       hasData = true;
                   }
                });

                if( hasData ) {
                    $(this).addClass('has-tacho-data')
                } else {
                    $(this).addClass('has-no-tacho-data');
                }
            } else {
                $(this).addClass('has-no-tacho-data');
            }
        });

        // attach additional click events for days without data
        $table.find('tbody td.date').not('.has-data').addClass('no-data');
        const $clickable = $table.find('tbody td.date.has-data, tbody td.date.has-tacho-data');

        this.#setupCalenderSelect($table);
        $clickable.off('click').on('click', (e) => {
            if(!this.isEditing) {
                this.displayDriverActivityTab(e);
            }
        });
    }

    triggerRemoteRebuildTables(tabName)
    {
        if (!tabName) {
            tabName = this._$hostingTab[0].className.split(' ')[1].substring(4);
        }
        tabName = (tabName.substring(0, 1).toUpperCase() + tabName.substring(1))
            .replace(/-([a-z])/, (m, p1) => p1.toUpperCase());

        super.triggerRemoteRebuildTables(`Driver${tabName}Tab`)
    }

    #toggleLinkedDays()
    {
        const $checkbox = this._$hostingTab.find('input#enable_links');
        if ($checkbox.prop('checked')) {
            this.#loadLinkedDays()
        } else {
            this.#clearLinkedDays()
        }
    }

    #loadLinkedDays () {

        const month = this._$hostingTab.find('#year_selector').val() + '-' + (Number.parseInt(this._$hostingTab.find('#month_selector').val()) + 1);
        const sourceURL = app.CACHE.URL_AJAX + 'tacho_drv/get/linked_days/' + this.combineParams({
            'driver_id': this.#driverId,
            'month': month
        });

        AjaxSync({
            url: sourceURL,
            method: 'GET',
            dataType: 'json'
        }, {
            done: this.#loadLinkedDaysSuccess
        });
    }

    #loadLinkedDaysSuccess(content) {
        const records = content.data;
        let dateForRange = DateTime.fromFormat(month, 'yyyy-M');
        const lastDay = dateForRange.endOf('month').toFormat('dd');
        const dates = {};
        for (let i = 1; i <= lastDay; ++i) {
            dateForRange = dateForRange.set({day: i});
            dates[dateForRange.toFormat('yyyyMMdd')] = {
                start: null,
                duration: null,
                entry: false,
            };
        }

        for (const i in records) {
            const record = records[i];
            const start = DateTime.fromISO(record.start);

            const key = start.toFormat('yyyyMMdd')
            if (dates.hasOwnProperty(key)) {
                dates[key].start = start
                dates[key].duration = record.duration
                dates[key].entry = record.entry
            }
        }

        for (const d in dates)
        {
            const record = dates[d];
            const cell = 'td#' + d;
            const $cell = this._$hostingTab.find(cell)

            const start = record.start ? record.start.toLocaleString(DateTime.TIME_24_SIMPLE) : '--:--'

            const $tag = $('<span>').addClass('linked_days')
                .text(start)
                .attr('title', record.duration);
            if (record.entry) {
                $tag.addClass('valid');
            } else {
                $tag.addClass('invalid');
            }

            $cell.prepend($tag);
        }
    }

    #clearLinkedDays () {
        this._$hostingTab.find('table.tacho_calendar').find('span.linked_days').remove();
    }

    #setupCalenderSelect($table) {
        const $editBtn = $table.find('.bulk-edit');
        const $editDropdown = $table.find('#edit-type');
        const $saveEditBtn = $table.find('#save-edit');
        $editBtn.unbind('click').on('click', (e) => {
            if(this.isEditing) {
                this.isEditing = false;
                $editBtn.html(`<i class="fa-duotone fa-edit"></i> Select days`).removeClass('button-grey');
                $table.find('.highlight').removeClass('highlight');
                $saveEditBtn.hide();
                $editDropdown.hide();
            } else {
                this.isEditing = true;
                $editBtn.html(`<i class="fa-duotone fa-xmark"></i> Cancel`).addClass('button-grey');
                $saveEditBtn.show();
                $editDropdown.show();
            }
        });

        const $clickable = $table.find('tbody td.date.has-data, tbody td.date.no-data, tbody td.date.has-tacho-data');
        $clickable.off('click').on('click', (_e) => {
            //$(e.currentTarget).toggleClass('highlight');
        });

        $saveEditBtn.unbind('click').on('click', (_e) => {
            const type = $editDropdown.val();
            const $highlighted = $table.find('.highlight');
            const dates = [];
            let mode = 'add';
            let totalExisting = 0;
            $.each($highlighted, (k, el) => {
                switch (type) {
                    case 'holiday':
                        if($(el).find('.holiday').length > 0) {
                            totalExisting++;
                        }
                        break;
                    case 'sick':
                        if($(el).find('.sick').length > 0) {
                            totalExisting++;
                        }
                        break;
                    case 'unpaid':
                        if($(el).find('.unpaid').length > 0) {
                            totalExisting++;
                        }
                        break;
                    case 'working_elsewhere':
                        if($(el).find('.not-working-here').length > 0) {
                            totalExisting++;
                        }
                        break;
                    case 'training':
                        if($(el).find('.training').length > 0) {
                            totalExisting++;
                        }
                        break;
                    default: break;
                }


                dates.push($(el).attr('id'));
            });

            if(totalExisting === $highlighted.length) {
                mode = 'delete';
            }

            if(!dates || dates.length === 0) {
                app.modalGeneric('', `Please select the dates you wish to update`)
                return;
            }

            switch(type) {
                case 'holiday':
                    this.ajaxAddDeleteEvent('holiday', null, dates, mode);
                    break;
                case 'sick':
                    this.ajaxAddDeleteEvent('sick', null, dates, mode);
                    break;
                case 'unpaid':
                    this.ajaxAddDeleteEvent('unpaid', null, dates, mode);
                    break;
                case 'working_elsewhere':
                    this.#addDeleteWorkingElsewhere(dates, mode, $editBtn);
                    break;
                case 'training':
                    this.ajaxAddDeleteEvent('training', null, dates, mode, $table);
                    break;
                default:
                    app.errors('Invalid event type selected.');
                    break;
            }
        });

        // The Below code allows click and drag over cells to highlight
        $table.find('td').unbind('mousedown').on('mousedown', (e) => {
            if(!this.isEditing) {
                return;
            }

            const el = $(e.currentTarget);
            if(e.which !== 1) {
                return;
            }

            this.isMouseDown = true;
            if(el.hasClass('week_summary') || (!el.hasClass('has-data') && !el.hasClass('no-data') && !el.hasClass('has-tacho-data'))) {
                return;
            }

            el.toggleClass('highlight');
            return false;
        }).on('mouseenter', (e) => {
            if(!this.isEditing) {
                return;
            }

            const el = $(e.currentTarget);
            if(!el.hasClass('date')) {
                return;
            }

            if(!this.isMouseDown) {
                return;
            }

            el.toggleClass('highlight');
        }).bind("selectstart", () => {
            return false;
        });

        $(document).on('mouseup', (e) => {
            if(!this.isMouseDown || !this.isEditing) {
                return;
            }

            this.isMouseDown = false;
            $(e.currentTarget).toggleClass('highlight');
            e.preventDefault();
            e.stopPropagation();
        });
    }

    #addDeleteWorkingElsewhere(days, mode, $editBtn) {
        days = days.map((d) => app.date_convert(`${d}`.replace(/(\d{4})(\d{2})(\d{2})/, '$3-$2-$1')));
        let url =  `${app.CACHE.URL_AJAX}tacho_drv/get/suppression/days:${days.join(',')}|driver_id:${app.URI[2]}`;
        if(mode) {
            url += `|mode:${mode}`;
        }

        const ajaxOpts = {
            url: url,
            method: 'GET',
        };

        let note;
        if(mode === 'add') {
            note = prompt('Please enter a Driver Suppression Reason');

            if(!note) {
                $editBtn.trigger('click');
                return;
            }

            ajaxOpts.method = 'POST';
            ajaxOpts.data = { note };
        }

        AjaxSync(ajaxOpts, {
            done: (res) => {
                if(res.status !== 'success') {
                    app.errors(res);
                    return;
                }

                this.isEditing = false;
                this.rebuildTables();
            }
        });
    }
}